<template>
  <div :class="$style.tagsList">
    <div v-for="(opt, index) in options" :key="opt.value + String(index)">
      <el-input
        v-if="opt.optId === inputTagEditId && isInputTagEdit"
        v-model="inputTagEdit"
        size="mini"
        @keyup.enter.native="handleInputTagConfirm($event, opt)"
      >
      </el-input>
      <el-tag
        v-else
        :class="$style.tag"
        closable
        disable-transitions
        @close="$emit('delete', opt)"
        @click="editOptions(opt, index)"
      >
        {{ opt.value }}
      </el-tag>
      <Icon
        v-if="opt.optId === inputTagEditId"
        name="save"
        :class="$style.icon"
        @click="handleInputTagConfirm($event, opt)"
      />
    </div>
    <div v-if="inputTagVisible">
      <el-input
        v-if="inputTagVisible"
        :class="$style.inputNewTag"
        v-model="inputTagValue"
        ref="saveTagInput"
        size="mini"
        @keyup.enter.native="handleInputConfirm($event)"
      ></el-input>
      <Icon
        name="save"
        :class="$style.icon"
        @click="handleInputConfirm($event)"
      />
    </div>
    <el-button
      v-else
      :class="$style.buttonNewTag"
      size="small"
      @click="showInput"
    >
      + Новый вариант
    </el-button>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon.vue'

export default {
  components: { Icon },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      inputTagVisible: false,
      inputTagValue: '',
      inputTagEdit: '',
      isInputTagEdit: false,
      inputTagEditId: ''
    }
  },

  computed: {
    newOptionsList() {
      return this.options.map((opt, index) => {
        opt.optId = index + 1 + opt.value
      })
    }
  },
  methods: {
    editOptions(data, index) {
      if (!data.optId) {
        data.optId = index + 1 + data.value
      }
      this.inputTagEditId = data.optId
      this.inputTagEdit = data.value
      this.isInputTagEdit = true
    },

    handleInputTagConfirm(event, data) {
      this.isInputTagEdit = false
      this.inputTagEditId = ''

      const isOptionExist = this.options.find((option) => option.value === this.inputTagEdit)

      if (isOptionExist) {
        alert('Такой элемент уже существует, создайте новый')
        event.target.blur()
        return
      }

      const isConfirm = confirm(
        `Вы точно хотите отредактировать ${this.placeholder} ?`
      )
      if (!isConfirm) return

      if (!this.inputTagEdit) {
        alert(
          `Нельзя сохранить пустой ${this.placeholder}. Но Вы можете удалить его`
        )
        return
      }

      data.value = this.inputTagEdit
    },

    handleInputConfirm(event) {
      if (!this.inputTagValue) {
        alert(
          `Нельзя сохранить пустой ${this.placeholder}.`
        )
        return
      }
      const isOptionExist = this.options.find((option) => option.value === this.inputTagValue)

      if (isOptionExist) {
        event.target.blur()
        alert('Такой элемент уже существует, создайте новый')
        return
      }

      if (this.inputTagValue) {
        this.options.push({ value: this.inputTagValue })
      }
      this.inputTagVisible = false
      this.inputTagValue = ''
    },

    showInput() {
      this.inputTagVisible = true
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    }
  }
}
</script>

<style lang="scss" module>
.tagsList {
  & > div {
    position: relative;
    margin-left: 1rem;
    margin-bottom: 0.625rem;
    & > div {
      input {
        padding-right: 2rem;
        width: auto;
      }
    }
  }
}
.icon {
  position: absolute;
  width: 0.85rem;
  height: 1rem;
  top: 0.5rem;
  right: 0.5rem;
  color: $cyan;
  cursor: pointer;
}
.buttonNewTag {
  margin-left: 0.625rem;
  height: 2rem;
  line-height: 1.875rem;
  padding-top: 0;
  padding-bottom: 0;
}
.inputNewTag {
  margin-left: 0.625rem;
  vertical-align: bottom;
}
</style>
